import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  subscription_plan_discount: [],
  subscription_plan: {},
  header: "What suits you best?",
  subHeader: "Compare our plans and choose the one that's right for you.",
  planChat: [],
  planCurrentPlanIndex: 0,
  planPreviewEnable: false,
  previewState: false,
  previouslyVisitedCouponPage: false,
  subCustomer: {
    data: [],
    total_pages: 0,
    current_page: 1,
    next_page: 1,
    total_count: 0,
  },
  paymentUser: {
    email: "",
    phone: "",
    cardholderName: "",
    state: "",
    address: "",
    country: "",
  },
  userSettings: {
    company_logo: "",
    company_name: "",
    address: "",
    phone: "",
    invoice_email: "",
    invoice_number_series:"",
    sale_tax:"",
    discount:false,
    tax:false
  },
  emailSettings: {
    email: "",
  },
  paymentSettings: {
    payment_success_url: "",
    payment_failure_url: "",
  },
  changePlanDetails:{
    plan_id:'',
    subscription_id:'',
    coupon_id:'',
    is_renew:true,
  },
  selectedPurchasePlanDetails:{
    plan_id:"",
    payment_method:"",
    promocode:"",
    plan_details:{}
  }
};

export const subscriptionSlice = createSlice({
  name: "subscription",
  initialState,
  reducers: {
    setSubScriptionPlanDiscount: (state, action) => {
      state.subscription_plan_discount = action.payload;
    },
    setSubScriptionPlan: (state, action) => {
      state.subscription_plan = action.payload;
    },
    setSubScriptionPlanHeader: (state, action) => {
      state.header = action.payload;
    },
    setSubScriptionPlanSubHeader: (state, action) => {
      state.subHeader = action.payload;
    },
    setPlanChat: (state, action) => {
      state.planChat = action.payload;
    },
    setPlanCurrentPlanIndex: (state, action) => {
      state.planCurrentPlanIndex = action.payload;
    },
    setPlanPreviewEnable: (state, action) => {
      state.planPreviewEnable = action.payload;
    },
    setPreviewState: (state, action) => {
      state.previewState = action.payload;
    },
    setsubCustomer: (state, action) => {
      state.subCustomer = action.payload;
    },
    setPaymentUser: (state, action) => {
        state.paymentUser = action.payload;
      },
    setUserSettings: (state, action) => {
      state.userSettings = action.payload;
    },
    updateCompanyLogo: (state, action) => {
      state.userSettings.company_logo = action.payload;
    },
    setEmailSettings: (state, action) => {
      state.emailSettings = action.payload;
    },
    setPaymentSettings: (state, action) => {
      state.paymentSettings = action.payload;
    },
    setChangePlanDetails:(state, action)=>{
      state.changePlanDetails = action.payload;
    },
    setSelectedPurchasePlanDetails:(state, action)=>{
      state.selectedPurchasePlanDetails = action.payload
    },
    setPreviouslyVisitedCouponPage:(state, action) =>{
      state.previouslyVisitedCouponPage = action.payload
    }
  },
});

export const {
  setSubScriptionPlanDiscount,
  setSubScriptionPlan,
  setSubScriptionPlanHeader,
  setSubScriptionPlanSubHeader,
  setPlanChat,
  setPlanCurrentPlanIndex,
  setPlanPreviewEnable,
  setPreviewState,
  setsubCustomer,
  setPaymentUser,
  setUserSettings,
  updateCompanyLogo,
  setEmailSettings,
  setPaymentSettings,
  setChangePlanDetails,
  setSelectedPurchasePlanDetails,
  setPreviouslyVisitedCouponPage
} = subscriptionSlice.actions;

export default subscriptionSlice.reducer;
