// src/store/authentications/reducers.js
import { createSlice } from "@reduxjs/toolkit";
import initialState from "./initialStates";

const formSlice = createSlice({
  name: "formConfig",
  initialState,
  reducers: {
    toggleField: (state, action) => {
      const { pageType, fieldName } = action.payload;
      if (state[pageType] && state[pageType][fieldName] !== undefined) {
        if (
          (fieldName === "confirm_password" || fieldName === "password") &&
          pageType === "SIGN_UP"
        ) {
          state[pageType][fieldName] = true;
        } else {
          state[pageType][fieldName] = !state[pageType][fieldName];
        }
      }
    },
    setField: (state, action) => {
      const { pageType, fieldName, value } = action.payload;
      console.log("setField", action.payload, state[pageType][fieldName]);
      if (state[pageType]) {
        state[pageType][fieldName] = value;
      }
    },
    setPageType: (state, action) => {
      const { pageType, value } = action.payload;
      if (state[pageType] && Object.keys(value).length) {
        Object.keys(value).forEach((fieldName) => {
          if (state[pageType][fieldName] !== undefined) {
            state[pageType][fieldName] = value[fieldName];
          }
        });
      }
    },
  },
});

export const { toggleField, setPageType, setField } = formSlice.actions;
export default formSlice.reducer;
